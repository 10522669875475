<template>
    <HPopin
        :value="value"
        @input="close"
        width="small"
        class="delete-product-popin"
    >
        <template #header>
            Confirmation
        </template>
        <div>
            <p>Etes-vous sûr de vouloir supprimer
              l'abonnement au {{ productTypeLabel }}
              "<span class="product-title">{{ productTitle }}</span>" ?</p>
        </div>
        <template #actions>
            <HBtn
                @click="onCancelBtnClick"
                style-type="secondary"
                class="cancel-btn"
                data-cy="cancel-btn"
            >Annuler</HBtn>
            <HBtn
                class="validate-btn"
                data-cy="validate-btn"
                @click="onDeleteBtnClick"
            >Valider</HBtn>
        </template>
    </HPopin>
</template>

<script>
    import _ from 'lodash'

    import Routes from "@/constants/routes"

    import {
      HPopin,
      HBtn
    } from "@happytal/bo-ui-library"

    export default {
        name: 'DeleteProductPopin',
        model: {
            prop: 'value',
            event: 'input'
        },
        components: {
            HPopin,
            HBtn
        },
        props: {
            value: {
                type: Boolean,
                default: false
            },
            product: {
                type: Object
            }
        },
        computed: {
            productTypeLabel () {
                const type = _.get(this.product, 'type', null)
                return type == 'product' ? 'produit' : 'service'
            },
            productTitle () {
                return _.get(this.product, 'title', '')
            }
        },
        methods: {
            close () {
                this.$emit('input', false)
            },
            onCancelBtnClick (e) {
                this.close()
                this.$emit('cancel')
            },
            onDeleteBtnClick (e) {
                this.close()
                this.$emit('validate')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .delete-product-popin::v-deep {

        .h-popin__dialog {
            min-height: 230px;
        }
        .product-title {
            font-weight: 600;
        }
    }
</style>
