<template>
    <HPopin
        :value="value"
        @input="close"
        width="small"
        class="confirm-exit-popin"
    >
        <template #header>
            Annuler les modifications ?
        </template>
        <div>
            <p>Êtes-vous sûr de vouloir quitter la page ?
              Les produits et services modifiés ne seront pas
              enregistrés dans l'abonnement du résident.</p>
        </div>
        <template #actions>
            <HBtn
                @click="onNoBtnClick"
                style-type="secondary"
                class="no-btn"
                data-cy="no-btn"
            >Non</HBtn>
            <HBtn
                class="yes-btn"
                data-cy="yes-btn"
                @click="onYesBtnClick"
            >Oui</HBtn>
        </template>
    </HPopin>
</template>

<script>
    import {
      HPopin,
      HBtn
    } from "@happytal/bo-ui-library"

    export default {
        name: 'ConfirmExitPopin',
        model: {
            prop: 'value',
            event: 'input'
        },
        components: {
            HPopin,
            HBtn
        },
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            close () {
                this.$emit('input', false)
            },
            onNoBtnClick (e) {
                this.close()
                this.$emit('no')
            },
            onYesBtnClick (e) {
                this.close()
                this.$emit('yes')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .confirm-exit-popin::v-deep {

        .h-popin__dialog {
            min-height: 230px;
        }
    }
</style>
