<template>
    <HCard
        type="free"
        class="subscription-card"
    >
        <div class="header">
            <div class="header-title">{{ product.title }}</div>
            <div
                v-if="frequencyVisible && product.mode == 'view'"
                class="frequency-label"
            >{{ frequencyLabel }}</div>
            <FrequencySelector
                v-else-if="frequencyVisible"
                v-model="product.frequencyInMonth"
                class="frequency-select"
                @input="(frequency) => {onFrequencyChange(frequency, product)}"
            />
        </div>
        <div class="content">
            <div class="product">
                <div class="product-cover">
                    <img class="product-image" :src="product.imageUrl" />
                </div>
                <div
                    class="product-description description-desktop"
                    v-html="product.description"
                ></div>
                <div class="product-price">
                    <HTooltip
                        v-if="quantityVisible && product.mode == 'view' && quantityLimited"
                        :max-width="250"
                        color="neutral"
                        class="quantity-tooltip"
                    >
                        <template #activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                v-on="on"
                                class="quantity-legend quantity-limited"
                            >{{ quantityLabel }}</div>
                        </template>
                        <span>{{ quantityText }}</span>
                    </HTooltip>
                    <div
                        v-else-if="quantityVisible && product.mode == 'view'"
                        class="quantity-legend"
                    >{{ quantityLabel }}</div>
                    <div
                        v-else-if="quantityVisible"
                        class="quantity-selector"
                    >
                        <HBtn
                            icon-only
                            style-type="tertiary"
                            icon="CircleMinusIcon"
                            size="small"
                            class="remove-btn"
                            data-cy="remove-btn"
                            :disabled="removeQuantityDisabled || product.quantity <= 1"
                            @click="onRemoveQuantityBtnClick(product)"
                        ></HBtn>
                        <div class="quantity-label">{{ product.quantity }}</div>
                        <HBtn
                            icon-only
                            style-type="tertiary"
                            icon="CirclePlusIcon"
                            size="small"
                            class="add-btn"
                            data-cy="add-btn"
                            :disabled="addQuantityDisabled || product.quantity >= maxQuantity"
                            @click="onAddQuantityBtnClick(product)"
                        ></HBtn>
                        <div class="quantity-bar"></div>
                        <HBtn
                            icon-only
                            icon="TrashIcon"
                            size="small"
                            class="delete-btn"
                            data-cy="delete-btn"
                            @click="onDeleteBtnClick(product)"
                        ></HBtn>
                    </div>
                    <div
                        class="total-label"
                        v-if="amountVisible"
                    >{{ amountLabel }}€</div>
                </div>
            </div>
            <div
                class="product-description description-mobile"
                v-html="product.description"
            ></div>
            <div class="vendor-label">{{ vendorLabel }}</div>
        </div>
        <div class="footer" v-if="footerVisible">
            <slot v-if="product.mode == 'view'" name="footer-view" />
            <slot v-if="product.mode == 'edit'" name="footer-edit" />
        </div>
    </HCard>
</template>

<script>
import {
  getFrequencyLabel
} from '@/modules/labels'

import {
  HCard,
  HBtn,
  HTooltip
} from '@happytal/bo-ui-library'

import FrequencySelector from '@/components/selectors/FrequencySelector'

export default {
    props: {
      product: {
        type: Object
      },
      frequencyVisible: {
        type: Boolean,
        default: true
      },
      footerVisible: {
        type: Boolean,
        default: true
      },
      amountVisible: {
        type: Boolean,
        default: true
      },
      quantityVisible: {
        type: Boolean,
        default: true
      },
      addQuantityDisabled: {
        type: Boolean,
        default: false
      },
      removeQuantityDisabled: {
        type: Boolean,
        default: false
      },
      maxQuantity: {
        type: Number,
        default: Infinity
      },
      quantityLimited: {
        type: Boolean,
        default: false
      }
    },
    components: {
      HCard,
      HBtn,
      HTooltip,
      FrequencySelector
    },
    computed: {
      vendorLabel () {
        switch (this.product.type) {
          case 'product':
            return `Produit vendu par ${this.product.shopName}`
          case 'service':
            return `Prestation réalisée par ${this.product.shopName}`
        }
      },
      frequencyLabel () {
        return getFrequencyLabel(this.product.frequencyInMonth)
      },
      quantityLabel () {
        const quantity = this.getRealQuantity()
        const asterisk = this.quantityLimited ? '*' : ''
        return `${quantity}${asterisk} x ${this.product.price} €`
      },
      quantityText () {
        const productTypeLabel = this.product.type == 'product' ? 'produits' : 'services'
        return `La quantité commandée de ${this.product.quantity} ${productTypeLabel} n'est pas entièrement disponible.`
      },
      amountLabel () {
        const quantity = this.getRealQuantity()
        return (quantity * this.product.price).toFixed(2)
      }
    },
    methods: {
      getRealQuantity () {
        return this.product.quoteQuantity ?
        Math.min(this.product.quantity, this.product.quoteQuantity) :
        this.product.quantity
      },
      onFrequencyChange (frequency, product) {
        this.$emit('update-frequency', {
          ...product,
          frequencyInMonth: frequency
        })
      },
      onRemoveQuantityBtnClick (product) {
        this.$emit('remove-quantity', product)
      },
      onAddQuantityBtnClick (product) {
        this.$emit('add-quantity', product)
      },
      onDeleteBtnClick (product) {
        this.$emit('delete-quantity', product)
      }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.subscription-card {
    font-family: Montserrat;
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        align-items: center;
        padding: 15px 15px 0px 15px;

        .header-title {
            flex-grow: 1;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 15px;
            color: rgba(var(--vs-primary), 1);
        }
        .frequency-label {
            flex-shrink: 0;
            width: 100px;
            margin: 0px 0px 0px 10px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            text-align: right;
            color: #606060;
        }
        .frequency-select {
            margin: 0px 0px 0px 15px;

            @media (min-width: map-get($breakpoints, sm)) {
                flex-shrink: 0;
                width: 150px;
            }
        }
    }
    .content {
        flex-grow: 1;
        padding: 20px 15px 15px 15px;

        .description-desktop {
            @media (max-width: map-get($breakpoints, sm)) {
                display: none;
            }
            @media (min-width: map-get($breakpoints, sm)) {
                display: block;
            }
        }
        .description-mobile {
            @media (max-width: map-get($breakpoints, sm)) {
                display: block;
                margin: 10px 10px 10px 10px;
            }
            @media (min-width: map-get($breakpoints, sm)) {
                display: none;
            }
        }

        .product {
            display: flex;

            .product-cover {
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80px;
                height: 80px;
                border: 1px solid #DDDDDD;
                border-radius: 6px;
                overflow: hidden;

                .product-image {
                    height: 100%;
                    object-fit: cover;
                }
            }
            .product-description {
                flex-grow: 1;
                margin: 0px 10px 0px 10px;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 20px;
                color: #000000;
            }
            .product-price {
                align-self: center;
                text-align: right;

                @media (max-width: map-get($breakpoints, sm)) {
                    flex-grow: 1;
                }

                .quantity-tooltip {

                }
                .quantity-legend {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: right;
                    color: #606060;
                }
                .quantity-limited {
                    display: inline-block;
                    padding: 5px 20px 5px 20px;
                    border-radius: 30px;
                    background: #F78D35;
                    font-weight: 600;
                    color: white;
                }
                .quantity-selector {
                    display: flex;
                    align-items: center;

                    @media (max-width: map-get($breakpoints, sm)) {
                        justify-content: flex-end;
                    }

                    .remove-btn {

                    }
                    .quantity-label {
                        margin: 0px 15px 0px 15px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 18px;
                        text-align: center;
                        color: rgba(var(--vs-primary), 1);
                    }
                    .add-btn {

                    }
                    .quantity-bar {
                        margin: 0px 15px 0px 12px;
                        width: 3px;
                        height: 20px;
                        border-left: 1px solid #DDDDDD;
                    }
                    .delete-btn {

                    }
                }
                .total-label {
                    margin: 6px 0px 0px 0px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 20px;
                    text-align: right;
                    color: #000000;
                }
            }
        }
        .vendor-label {
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 10px;
            text-align: right;
            color: #BDBDBD;
        }
    }
    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 15px 15px 15px;
        border-top: 1px solid #DDDDDD;

        @media (max-width: map-get($breakpoints, sm)) {
            justify-content: center;
        }
    }
}
</style>
